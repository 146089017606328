<template>
  <div 
    :class="[
      'input-password',
      {
        visible
      }
    ]"
  >
    <input 
      v-model="password"
      :type="inputType" 
      :name="name" 
      :id="id"
      :placeholder="placeholder"
      class="input-password__custom"
    >
    
    <div 
      @click="visible = !visible"
      class="input-password--btn-toggle"
    >
      <svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <line x1="13.323" y1="0.381693" x2="0.322971" y2="11.3817" stroke="#0077DF"/>
        <line x1="13.323" y1="0.381693" x2="0.322971" y2="11.3817" stroke="black" stroke-opacity="0.2"/>
        <path d="M8.8 6C8.8 6.94677 7.99411 7.71429 7 7.71429C6.00589 7.71429 5.2 6.94677 5.2 6C5.2 5.05323 6.00589 4.28571 7 4.28571C7.99411 4.28571 8.8 5.05323 8.8 6Z" stroke="#0077DF" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M8.8 6C8.8 6.94677 7.99411 7.71429 7 7.71429C6.00589 7.71429 5.2 6.94677 5.2 6C5.2 5.05323 6.00589 4.28571 7 4.28571C7.99411 4.28571 8.8 5.05323 8.8 6Z" stroke="black" stroke-opacity="0.2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M1 6C1.96008 3.65865 4.20162 2 7 2C9.79838 2 12.0399 3.65865 13 6C12.0399 8.34135 9.79838 10 7 10C4.20162 10 1.96008 8.34135 1 6Z" stroke="#0077DF" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M1 6C1.96008 3.65865 4.20162 2 7 2C9.79838 2 12.0399 3.65865 13 6C12.0399 8.34135 9.79838 10 7 10C4.20162 10 1.96008 8.34135 1 6Z" stroke="black" stroke-opacity="0.2" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CommonInputPassword',
  props: {
    placeholder: {
      type: String,
      default: 'Digite sua senha'
    },
    id: {
      type: String,
      required: true
    },
    name: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      password: '',
      visible: false
    }
  },
  watch: {
    password(newPassword, oldPassword) {
      this.$emit('change', { newPassword, oldPassword });
    }
  },
  computed: {
    inputType() {
      return this.visible ? 'text' : 'password';
    }
  }
}
</script>

<style scoped>
.input-password {
  display: flex;
  align-items: center;
  background-color: #FFF;
  padding: 15px 4px 15px 20px;
  border: 1px solid;
  height: 44px;
}
.input-password__custom {
  border: none;
  background-color: transparent;
  padding: 0;
  font-size: 12px;
  height: 100%;
}
.input-password--btn-toggle {
  padding: 0 10px;
  cursor: pointer;
}
.visible line {
  display: none;
}
</style>