var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
    'login-container-form',
    {
      differentPasswords: _vm.differentPasswords
    }
  ]},[(!_vm.sendNewPassword)?[_vm._m(0),_c('CommonErrorMessage',{staticClass:"login__error-message",attrs:{"show":_vm.error ||
        _vm.errorCurrent ||
        _vm.errorNewPassword ||
        _vm.errorConfirmPassword ||
        _vm.differentPasswords,"message":_vm.errorMessage}}),_c('div',[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.handleNewPassword.apply(null, arguments)}}},[_c('label',{class:[
            'new-password__label',
            {
              error: _vm.errorCurrent,
            }
          ],attrs:{"for":"currentpassword"}},[_vm._v(" Digite sua senha atual* "),_c('CommonInputPassword',{staticClass:"new-password__input",attrs:{"id":"currentpassword","placeholder":"sua senha atual","name":"currentpassword"},on:{"change":_vm.changeCurrentPassword}})],1),_c('label',{class:[
            'new-password__label',
            {
              error: _vm.errorNewPassword,
            }
          ],attrs:{"for":"password"}},[_vm._v(" Digite a nova senha* "),_c('CommonInputPassword',{staticClass:"new-password__input",attrs:{"id":"password","placeholder":"sua nova senha","name":"password"},on:{"change":_vm.changeNewPassword}})],1),_c('label',{class:[
            'new-password__label',
            {
              error: _vm.errorConfirmPassword || _vm.differentPasswords,
            }
          ],attrs:{"for":"confirmpassword"}},[_vm._v(" Confirme a nova senha* "),_c('CommonInputPassword',{staticClass:"new-password__input",attrs:{"id":"confirmpassword","placeholder":"confirme sua nova senha","name":"confirmpassword"},on:{"change":_vm.changeConfirmPassword}})],1),_c('button',{class:{
            disabled: _vm.error || _vm.loading
          },attrs:{"type":"submit"}},[_vm._v(" "+_vm._s(_vm.buttonText)+" ")])])]),_vm._m(1)]:[_vm._m(2),_c('div',[_c('router-link',{staticClass:"login-container--btn-to-login",attrs:{"to":"/login"}},[_vm._v(" Ir para o login ")])],1)]],2)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"login-container__form-text"},[_c('h1',[_vm._v("Cadastro de nova senha")]),_c('p',{staticClass:"new-password__subtitle"},[_vm._v(" IMPORTANTE: Para manter a sua segurança, a sua senha deve ter obrigatoriamente "),_c('b',[_vm._v(" letras minúsculas, maiúsculas e numerais. ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"login-container-form-seguro"},[_c('span',[_c('img',{attrs:{"src":require("@/assets/img/tela-inicial/seguro.svg"),"alt":"Acesso seguro 100%"}}),_vm._v(" Acesso 100% seguro ")]),_c('p',[_vm._v("Seus dados pessoas estão protegidos, todas as "),_c('br'),_vm._v(" informações são criptografadas para a sua segurança. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"login-container__form-text"},[_c('h1',[_c('img',{attrs:{"src":require("@/assets/img/tela-inicial/check-laranja.svg"),"alt":"E-mail de Recuperação de Senha enviado!"}}),_c('br'),_vm._v(" Senha alterada com sucesso ")]),_c('p',[_vm._v("Faça o login novamente!")])])
}]

export { render, staticRenderFns }