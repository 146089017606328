<template>
  <div 
    :class="[
      'login-container-form',
      {
        differentPasswords
      }
    ]"
  >
    <template v-if="!sendNewPassword">
      <div class="login-container__form-text">
        <h1>Cadastro de nova senha</h1>
        <p class="new-password__subtitle">
          IMPORTANTE: Para manter a sua segurança, a sua senha deve 
          ter obrigatoriamente 
          <b>
            letras minúsculas, maiúsculas e numerais.
          </b>
        </p>
      </div>
  
      <CommonErrorMessage 
        :show="
          error ||
          errorCurrent ||
          errorNewPassword ||
          errorConfirmPassword ||
          differentPasswords
        "
        :message="errorMessage" 
        class="login__error-message"
      />
      
  
      <div>
        <form @submit.prevent="handleNewPassword">
          <label 
            :class="[
              'new-password__label',
              {
                error: errorCurrent,
              }
            ]" 
            for="currentpassword"
          >
            Digite sua senha atual*
            <CommonInputPassword 
              id="currentpassword"
              placeholder="sua senha atual"
              name="currentpassword"
              class="new-password__input"
              @change="changeCurrentPassword"
            />
          </label>
          <label 
            :class="[
              'new-password__label',
              {
                error: errorNewPassword,
              }
            ]" 
            for="password"
          >
            Digite a nova senha*
            <CommonInputPassword 
              id="password"
              placeholder="sua nova senha"
              name="password"
              class="new-password__input"
              @change="changeNewPassword"
            />
          </label>
          <label 
            :class="[
              'new-password__label',
              {
                error: errorConfirmPassword || differentPasswords,
              }
            ]" 
            for="confirmpassword"
          >
            Confirme a nova senha*
            <CommonInputPassword 
              id="confirmpassword" 
              placeholder="confirme sua nova senha"
              name="confirmpassword" 
              class="new-password__input"
              @change="changeConfirmPassword"
            />
          </label>
          <button 
            :class="{
              disabled: error || loading
            }"
            type="submit"
          >
            {{ buttonText }}
          </button>
        </form>
      </div>
      <div class="login-container-form-seguro">
        <span>
          <img src="@/assets/img/tela-inicial/seguro.svg" alt="Acesso seguro 100%">
          Acesso 100% seguro
        </span>
        <p>Seus dados pessoas estão protegidos, todas as <br>
          informações são criptografadas para a sua segurança.
        </p>
      </div>
    </template>
    <template v-else>
      <div class="login-container__form-text">
        <h1>
          <img src="@/assets/img/tela-inicial/check-laranja.svg" alt="E-mail de Recuperação de Senha enviado!"> 
          <br> 
          Senha alterada com sucesso
        </h1>
        <p>Faça o login novamente!</p>
      </div>
      <div>
        <router-link 
          to="/login" 
          class="login-container--btn-to-login"
        >
          Ir para o login
        </router-link>
      </div>
    </template>
  </div>
</template>

<script>
import CommonInputPassword from '../components/common/CommonInputPassword.vue';
export default {
  name: 'Login',
  components: {
    CommonInputPassword,
    CommonErrorMessage: () => import(/* webpackChunkName: "CommonErrorMessage" */ '@/components/common/CommonErrorMessage.vue')
  },
  data() {
    return {
      currentPassword: '',
      newPassword: '',
      confirmPassword: '',

      error: false,
      errorCurrent: false,
      errorNewPassword: false,
      errorConfirmPassword: false,
      differentPasswords: false,
      errorMessage: 'Todos os campos são obrigatórios.',

      loading: false,
      sendNewPassword: false,
    }
  },
  computed: {
    buttonText() {
      return this.loading ? 'Carregando...' : 'Confirmar';
    },
    validAllFields() {
      return (
        this.currentPassword.length > 1 &&
        this.newPassword.length > 1 &&
        this.confirmPassword.length > 1 &&
        !this.differentPasswords
      );
    }
  },
  methods: {
    changeCurrentPassword({ newPassword }) {
      if (newPassword.length < 1) {
        this.errorCurrent = true;
        this.errorMessage = 'Todos os campos são obrigatórios.';
      } else {
        this.errorCurrent = false;
      }

      this.currentPassword = newPassword;
    },
    changeNewPassword({ newPassword }) {
      if (newPassword.length < 1) {
        this.errorNewPassword = true;
        this.errorMessage = 'Todos os campos são obrigatórios.';
      } else {
        this.errorNewPassword = false;
      }

      if (newPassword === this.confirmPassword && this.differentPasswords) {
        this.differentPasswords = false;
      } 

      this.newPassword = newPassword;
    },
    changeConfirmPassword({ newPassword }) {
      if (newPassword.length < 1) {
        this.errorConfirmPassword = true;
        this.errorMessage = 'Todos os campos são obrigatórios.';
      } else{
        this.errorConfirmPassword = false;
      } 

      if (newPassword !== this.newPassword) {
        this.differentPasswords = true;
        this.errorMessage = 'Sua nova senha não corresponde.';
      } else {
        this.differentPasswords = false;
      }

      this.confirmPassword = newPassword;

      
    },
    async handleNewPassword() {
      if (this.validAllFields && !this.loading) {
        this.loading = true;

        await this.$axios.patch(
          '/usuarios/senha', 
          { 
            senhaAtual: this.currentPassword, 
            novaSenha: this.newPassword,
            confirmacaoNovaSenha: this.confirmPassword
          }
        ).catch(err => {
          this.errorCurrent = true;
          this.errorMessage = err.data.message;
          return err;
        });

        if (!this.errorCurrent) {
          this.sendNewPassword = true;
          await this.$store.dispatch('logoutUser');
        }
        
        this.loading = false;
      }
    }
  },
}
</script>

<style>
.login-container-form .login-container--btn-recover-password {
  font-size: 14px;
  color: #0077DF;
  text-decoration: underline;
  background: transparent;
  height: unset;
  gap: 5px;
  font-weight: normal;
  justify-content: flex-start;
}
</style>

<style scoped>
.new-password__subtitle {
  max-width: 413px;
}
.new-password__label {
  margin-bottom: 20px;
}
.new-password__input {
  max-width: 363px;
}
a.login-container--btn-to-login {
  color: #fff;
}
</style>